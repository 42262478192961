import React, { useEffect } from 'react';
import { Adsense } from '@ctrl/react-adsense';

function GoogleAdsenseBoxDisplay() {
    useEffect(() => {
        if (!document.querySelector(`script[src*="adsbygoogle"]`)) {
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_ID}`;
            script.crossOrigin = 'anonymous';
        
            document.body.appendChild(script);
        
            return () => {
                if (document.body.contains(script)) {
                    document.body.removeChild(script);
                }
            };
        }
    }, []);

    useEffect(() => {
        // Crear una función para manejar la inicialización del anuncio
        const initAd = () => {
            // Obtener todos los elementos de anuncio existentes
            const adElements = document.querySelectorAll('ins.adsbygoogle');
            
            // Encontrar el último elemento de anuncio (el que acabamos de agregar)
            const currentAd = adElements[adElements.length - 1];
            
            // Solo inicializar si el elemento existe y no ha sido inicializado
            if (currentAd && !currentAd.hasAttribute('data-adsbygoogle-initialized')) {
                currentAd.setAttribute('data-adsbygoogle-initialized', 'true');
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('Error al cargar el anuncio:', e);
                }
            }
        };

        // Esperar a que adsbygoogle esté disponible
        if (window.adsbygoogle) {
            // Pequeño retraso para asegurar que el elemento ins esté en el DOM
            setTimeout(initAd, 100);
        } else {
            // Si adsbygoogle no está disponible, esperar a que se cargue
            window.addEventListener('load', initAd);
            return () => window.removeEventListener('load', initAd);
        }
    }, []);

    return (
        <div className={'card flex-column p-1 m-2 position-relative w-100-mb'}
             style={{
                 display: process.env.REACT_APP_ADS_GOOGLE_ADSENSE === 'active' ? 'flex' : 'none',
                 minWidth: '290px'
             }}>
            <Adsense
                client={process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_ID}
                slot={process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_SLOT}
                style={{ display: 'block' }}
                layoutKey='-81+eo+1+2-2'
                format="fluid"
                adTest='on'
            />
        </div>
    );
}

export default GoogleAdsenseBoxDisplay;