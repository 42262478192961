import React, {useEffect, useState} from 'react';
import {getRelatedCollections} from '../../services/PropertyService'
import Item from '../home/items/ItemProduct'
import {loading} from '../../utils/app_functions'
import {Skeleton} from "antd";
import {Box, LinearProgress} from "@mui/material";
import DetectComponentView from '../includes/DetectComponentView';
import GoogleAdsenseBoxDisplay from "../ads/GoogleAdsenseBoxDisplay";
import AdsterraBoxDisplay from "../ads/AdsterraBoxDisplay";

export default class RelatedAdWithoutCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collection: [],
            requestStatus: false,
            hasCollection: false,
            isInView: false,
        }
    }

    componentDidMount() {
        if(!this.state.requestStatus){
            const config_init = {
                start_id: parseInt(this.props.property_id),
                limit: 9,
                order_by: "desc"
             }  
             console.log('config_init', config_init)
            this.getCollection(this.props.property_id, config_init);
        }
    }

    getCollection = async (property_id, data)=>{
        loading(true);
        this.setState({requestStatus: true});

        return getRelatedCollections(property_id, data)
            .then(res => {
                this.setState({hasCollection: res.data.has_more_properties});
                this.setState({collection: this.state.collection.concat(res.data.properties)});
                this.setState({requestStatus: false});
                loading(false);

            }).catch(err => {
                console.log('err', err)
                this.setState({requestStatus: false});
                return loading(false);
            })
    }

    setStatus = (elInView) => {
        this.setState({
            isInView: elInView
        });
    };

    render() {
        //console.log('this.state.isInView', this.state.isInView)
        //console.log('this.state.hasCollection', this.state.hasCollection)

        if(this.state.isInView && this.state.hasCollection){
            this.setState({
                isInView: false,
                hasCollection: false,
            });

            const last_product_loaded = this.state.collection[this.state.collection.length -1];

            console.log('last_product_loaded.id', last_product_loaded.id)

            if(last_product_loaded !== undefined){
                let new_config = {
                    start_id: last_product_loaded.id,
                    limit: 9,
                    order_by: "desc"
                };
                console.log('new_config', new_config)
                this.getCollection(this.props.property_id, new_config);

            }

        }

        return (
            <div className={this.props.className} style={{paddingBottom: 400}}>
                {
                    this.state.collection.map((property, index)=>{
                        return <Item key={property.id} property={property} />
                        /*
                        if(index === 2 || index === 7 || index === 12){
                            return <div className={'d-flex flex-row flex-wrap align-items-center w-100-mb'}>
                                <Item key={property.id} property={property} />
                            </div>
                        }else{
                            return <Item key={property.id} property={property} />
                        }*/
                        
                        {/*else if(index === 0 || index === 4 || index === 10){
                            
                            return <div className={'d-flex flex-row flex-wrap align-items-center w-100-mb'}>
                                <AdsterraBoxDisplay />
                                
                                <Item key={property.id} property={property} />
                            </div>
                        }*/}
                        
                    })
                }

                <DetectComponentView setElementStatus={(status) => this.setStatus(status)} />

                <Box sx={{ width: '100%' }} style={{display: this.state.requestStatus ? 'block' : 'none'}}>
                    <LinearProgress />
                </Box>

            </div>

        )
    }

}