import React from 'react';
import Nav from "../components/Nav";
import CoverBusiness from "../img/business/cover.svg";
import CollectionRow from '../components/home/CollectionRow';
import PropertyTradingModeRow from '../components/home/PropertyTradingModeRow';
import SearchResult from '../components/home/SearchResult';
import Footer from '../components/home/footer/Footer';

export default class Home extends React.Component {

    // configLinksNavigation = [
    //     {
    //         label: 'Portada',
    //         url: '/',
    //     },
    //     {
    //         label: 'Ofertas',
    //         url: null,
    //     },
    // ];

    render() {
        return (
            <div style={{backgroundColor: 'rgb(0 182 220 / 38%)'}}>
                <Nav />

                {process.env.REACT_APP_BUSINESS_USE_COVER &&
                <div className={'w-100 shadow-down-medium show-only-mb'} style={{marginTop: 45}}>
                    <img src={CoverBusiness} alt="" style={{width: '100%'}}/>
                </div>}
                {/*
                <CollectionRow />
                */} 
                <SearchResult />

                <Footer />
            </div>
        )
    }
}