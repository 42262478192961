import React, {useEffect, useState, useContext} from 'react';
import {getRelatedCollectionsByCategory} from '../../services/PropertyService'
import Item from '../home/items/ItemProduct'
import {loading} from '../../utils/app_functions'
import {Skeleton} from "antd";
import {Box, LinearProgress} from "@mui/material";
import DetectComponentView from '../includes/DetectComponentView';
import GoogleAdsenseBoxDisplay from '../ads/GoogleAdsenseBoxDisplay';
import AdsterraBoxDisplay from "../ads/AdsterraBoxDisplay";

export default class RelatedAd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // property_id: useContext(projectContext),
            collection: [],
            requestStatus: false,
            hasCollection: false,
            config: {
                "start_id": null,
                "limit": 27,
                "order_by": "desc"
            },
            isInView: false,
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if(!this.state.requestStatus){
            this.getCollection(true, this.props.property_id, this.state.config);
        }
    }

    handleChange() {
        this.setState({property_id: this.props.property_id});
        this.getCollection(true, this.props.property_id, this.state.config);
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({property_id: nextProps.property_id})
    // }

    getCollection = async (first_mount_component, property_id, data)=>{
        loading(true);
        this.setState({requestStatus: true});

        return getRelatedCollectionsByCategory(property_id, data)
            .then(res => {

                if(first_mount_component){
                    this.setState({collection: this.state.collection.concat(res.data.properties)});
                }else{
                    if(res.data.has_more_properties){
                        this.setState({collection: this.state.collection.concat(res.data.properties)})
                        this.setState({hasCollection: true});
                    }else{
                        this.setState({hasCollection: false});
                    }
                }

                this.setState({requestStatus: false});
                loading(false);

            }).catch(err => {
                this.setState({requestStatus: false});
                return loading(false);
            })
    }

    setStatus = (elInView) => {
        this.setState({
            isInView: elInView
        });
    };

    render() {

        return (
            <div className={this.props.className}>
                <GoogleAdsenseBoxDisplay />
                {
                    this.state.collection.map((property, index)=>{
                        return <Item key={property.id} property={property} />
                        /*if(index === 2 || index === 7 || index === 12){
                            return <div key={index} className={'d-flex flex-row flex-wrap align-items-center w-100-mb'}>
                                <GoogleAdsenseBoxDisplay />
                                <Item key={property.id} property={property} />
                            </div>
                        }else if(index === 0 || index === 4 || index === 10){
                            
                            return <div className={'d-flex flex-row flex-wrap align-items-center w-100-mb'}>
                                <AdsterraBoxDisplay />
                                
                                <Item key={property.id} property={property} />
                            </div>
                        }else{
                            return <Item key={property.id} property={property} />
                        }*/
                    })
                }

                <DetectComponentView setElementStatus={(status) => this.setStatus(status)} />

                <Box sx={{ width: '100%' }} style={{display: this.state.requestStatus ? 'block' : 'none'}}>
                    <LinearProgress />
                </Box>

            </div>

        )
    }

}